import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Тату-студія Півонія
			</title>
			<meta name={"description"} content={"Увійдіть у світ, де сходяться творчість та ремесло.У Півонія ми не просто татуюємо - ми створюємо особисті шедеври."} />
			<meta property={"og:title"} content={"Головна | Тату-студія Півонія"} />
			<meta property={"og:description"} content={"Увійдіть у світ, де сходяться творчість та ремесло.У Півонія ми не просто татуюємо - ми створюємо особисті шедеври."} />
			<meta property={"og:image"} content={"https://zakarpattiazones.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(255, 186, 0, 0.3) 0%,rgba(255, 186, 10, 0.4) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://zakarpattiazones.live/img/1.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15" text-align="left">
			<Text
				margin="0px 0px 30px 0px"
				text-align="left"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				Ласкаво просимо в студію татуювань
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="left"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="--primary"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Півонія
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="--light"
				text-align="left"
				padding="0px 320px 0px 0px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Увійдіть у світ, де сходяться творчість та ремесло.У Півонія ми не просто татуюємо - ми створюємо особисті шедеври. Незалежно від того, чи це ваша перша татуювання чи доповнення до вашої колекції, наша студія присвячена забезпеченню унікального та комфортного досвіду.Приєднуйтесь до нас і давайте принесемо своє бачення до вашої шкіри.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Про Півонію
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
				>
					Студія татуювань Півонія - це більше, ніж просто місце, де можна зробити татуювання.Це творче святилище, де мистецтво та індивідуальний вираз оживають на шкірі.Наша студія поєднує в собі розслаблену атмосферу з найвищими стандартами професіоналізму та гігієни, забезпечуючи незабутній та безпечний досвід татуювань.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Text
				margin="0px 0px 50px 0px"
				font="--headline2"
				color="--dark"
				sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Чому варто вибрати нас?
			</Text>
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://zakarpattiazones.live/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					- Досвідчені художники: Наша талановита команда художників пропонує різноманітний спектр стилів, від традиційного до сучасного, гарантуючи, що їхнє бачення перетвориться на мистецтво.
<br />
<br />
- Персоналізовані дизайни: Ми спеціалізуємось на створенні дизайнів татуювання, коли вони стосуються їх історії та особистого стилю.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						- Гігієна останнього покоління: Ваша безпека - це наш пріоритет.Ми використовуємо останні методи стерилізації та високоякісні фарби.
<br />
<br />
- Консультативний підхід: Ми віримо в процес спільної роботи, де ваші ідеї почуті та вдосконалюються за допомогою спеціалізованих настанов.
					</Text>
					<Button href="/contacts" type="link" text-decoration-line="initial">
						Звʼязатися
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://zakarpattiazones.live/img/3.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});